import axios from "axios"
import sessionMiddleware from "./utils/sessionMiddleware";

const axiosRequest = (
  query,
  method = "GET",
  data = undefined,
  headers = undefined
) => {
  // Request URL
  const url = `https://dev.mavryck.com/service/PMIntelCore/rest/${query}`

  let authHeader;
  if (query !== "login/credentailsCheck") {
    const token = localStorage.getItem("token")
    if (!token) {
      console.warn("No token found. Request aborted.");
      return;
    }

    authHeader = {
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`,
    }
  } else {
    authHeader = {
      "Access-Control-Allow-Origin": "*"
    }
  }

  // Request Headers
  const reqHeaders = {
    headers: authHeader,
    crossDomain: true,
    withCredentials: true,
    method,
    url,
  }

  // If POST
  if (method === "POST" && data) {
    reqHeaders.data = data
  }

  // If PUT
  if (method === "PUT" && data) {
    reqHeaders.data = data
  }

  // If PUT
  if (method === "DELETE" && data) {
    reqHeaders.data = data
  }

  // If Custom Header Included
  if (headers) {
    reqHeaders.headers = {
      ...reqHeaders.headers,
      ...headers,
    }
  }

  return axios(reqHeaders)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        console.error('Server Error:', error.response.data);
        if (error.response.status === 401 && error.response.data === "Invalid or expired token.") {
          sessionMiddleware()
        }
        return error.response;
      } else if (error.request) {
        console.error('Network Error:', error.request);
        return error.request;
      } else {
        console.error('Error:', error.message);
        return error.message;
      }
    })
}

export default axiosRequest

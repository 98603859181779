import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import AnnotationLayout from './components/Annotation';

import App from './App';
import store from './store/index';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
          <AnnotationLayout>
            <App />
          </AnnotationLayout>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
import { createSlice } from '@reduxjs/toolkit';

const gulerMakSlice = createSlice({
    name: "gulerMak",
    initialState: {
      activeFeature: "Core",
      internalActiveFea: "",
      openPrognosis: false,
    }, // Predictions // Oculus DV // Core // Pattern Recognition // Diagnostics // Scenario Modeling
    reducers: {
      changeFeature(state, action) {
        state.activeFeature = action.payload;
      },
      changeInternalFeature(state, action) {
        state.internalActiveFea = action.payload;
      },
      changeFeaturetoPrognosis(state, action) {
        state.activeFeature = action.payload;
        state.openPrognosis = true;
      },
    },
});

export default gulerMakSlice;
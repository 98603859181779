import { createSlice } from '@reduxjs/toolkit';

const dummyRiskLogSlice = createSlice({
    name: "dummyRiskLog",
    initialState: {
        riskLogData: [],
        showRisksLogGrid: false,
        selectedRisk: null,
        showRmFormsTab: false
    },
    reducers: {
        updateRiskLogData(state, action) {
            state.riskLogData = action.payload;
        },
        showRisksLogHandler(state, action) {
            state.showRisksLogGrid = action.payload;
        },
        selectRisk(state, action){
            state.selectedRisk = action.payload;
        },
        showRmFormsTab(state, action){
            state.showRmFormsTab = action.payload;
        }
    }
});

export default dummyRiskLogSlice;
import React, { useEffect, useState } from 'react';

const getInitialLetter = (name) => {
  if (!name) return '';
  return name.charAt(0).toUpperCase();
};

const Annotation = ({ annotation, onEdit, onHover, isHovered }) => {
  const [scrollPosition, setScrollPosition] = useState({ scrollX: 0, scrollY: 0 });

  const handleScroll = () => {
    setScrollPosition({
      scrollX: window.scrollX,
      scrollY: window.scrollY,
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className="annotation"
      style={{
        position: 'absolute',
        top: annotation.y,
        left: annotation.x,
        cursor: 'pointer',
      }}
      onClick={() => onEdit(annotation.id)}
      onMouseEnter={() => onHover(annotation)}
      onMouseLeave={() => onHover(null)}
    >
      <div className="commentNode">{getInitialLetter(annotation.userName)}</div>
      {isHovered && <div className="annotation-text">{annotation.comment}</div>}
    </div>
  );
};

export default Annotation;

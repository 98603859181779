import store, { userNotifyActions } from "../store";

const sessionMiddleware = (props) => {
    store.dispatch(userNotifyActions.showNotify(true))
    setTimeout(() => {
        localStorage.clear();
        window.location.reload();
    }, 2000);
};

export default sessionMiddleware;
import { useSelector, useDispatch } from "react-redux";

import { FaArrowUpRightFromSquare } from "react-icons/fa6";

import { timeManagerActions, generalLoaderActions } from "../../store";

import "./MavryckLoader.css"



const MavryckLoader = (props) => {
    const dispatch = useDispatch();
    const loadingVal = useSelector(state => state.generalLoader.progress);
    const scenaroModErr = useSelector(state => state.errorInfoHandler.error)

    const featureHnadler = (payload) => {
        dispatch(timeManagerActions.changeFeature(payload));
        dispatch(generalLoaderActions.showLoader(false));
        dispatch(generalLoaderActions.loaderValue(0));
    }

    return (
        <div className="backdrop">
            <div className="loading-data-box" id="loading-data-box">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Mavryck" />

                <div className="d-flex flex-column align-items-center">
                    <p>Mavryck is thinking</p>
                </div>
                {scenaroModErr &&
                    <p>Error Loading Some Data <button onClick={()=>featureHnadler("Core")} className="text-primary">Please Try Again</button>.</p>
                }

                <div className="spinner">
                    <span>{loadingVal}%</span>
                    <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>

                {/* <div className="d-flex align-items-center gap-4">
                    <p id="articleTitle" className="quote"></p>
                    <a id="articleLink" href="#" target="_blank"><FaArrowUpRightFromSquare /></a>
                </div> */}
            </div>
        </div>
    )
};

export default MavryckLoader;

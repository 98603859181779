import { createSlice } from '@reduxjs/toolkit';

const userNotifySlice = createSlice({
    name: "userNotify",
    initialState: {
      showNotify:  false,
    },
    reducers: {
        showNotify(state, action) {
            state.showNotify = action.payload;
        }
    },
});

export default userNotifySlice;